export const responseStatus = {
  SUCCESS: 200,
  NOCONTENT: 204,
  BADREQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  REQUESTTIMEOUT: 408,
  RequestTookMoreThanExpected: 429,
  INTERNALSERVERERROR: 500,
  GatewayTimeOut: 504
};
